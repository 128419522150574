import React from 'react'
import styled from '@emotion/styled'
import x from '../../images/x@3x.png'
import check from '../../images/check@3x.png'
import displayFileSize from '../../util/displayFileSize'

const FileUploadContainer = styled.div`
  display: flex;
  width: 100%;

  .icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;

    img {
      width: 100%;
      margin: 0 auto;
      height: 11px;
      width: 11px;
      display: block;
    }
  }

  .error .icon {
    background-color: rgba(193, 28, 92, 0.9);
  }

  .success .icon {
    background-color: #a5c93d;
  }

  .icon.close {
    width: 15px;
    height: 15px;
    background-color: rgba(0, 0, 0, 0.45);

    img {
      position: relative;
      height: 7px;
      width: 7px;
    }
  }

  .close-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    background: #fff;
    position: absolute;
    right: 5px;
    top: 10px;
    bottom: 10px;
    z-index: 2;
    min-width: 20%;
  }

  span {
    flex: 1;
    margin-right: 15px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 18px;
    &.file-size {
      text-align: right;
      padding-right: 13px;
      color: rgba(0, 0, 0, 0.51);
      font-size: 16px;
      transform: translateY(3px);
    }
  }

  .error,
  .success,
  .empty {
    width: 100%;
    display: flex;
    align-items: center;
  }

  @media (min-width: ${props => props.theme.screenWidth.medium}) {
    span {
      font-size: 26px;
    }
  }
`

function FileError({ onRemove, fileName, fileSize }) {
  function handleRemove() {
    onRemove(fileName)
  }

  return (
    <FileUploadContainer>
      <div className={'error'}>
        <div className="icon">
          <img src={x} className="icon" alt="Error" />
        </div>

        <span>File upload was unsuccessful.</span>

        <div className="close-container">
          <span className="file-size">{displayFileSize(fileSize, 1)}</span>

          <div className="icon close" onClick={handleRemove}>
            <img src={x} alt="Close" />
          </div>
        </div>
      </div>
    </FileUploadContainer>
  )
}

function FileSuccess({ fileName, fileSize, onRemove }) {
  function handleRemove() {
    onRemove(fileName)
  }

  return (
    <FileUploadContainer>
      <div className={'success'}>
        <div className="icon">
          <img src={check} alt="Success" />
        </div>

        <span>{fileName}</span>

        <div className="close-container">
          <span className="file-size">{displayFileSize(fileSize, 1)}</span>

          <div className="icon close" onClick={handleRemove}>
            <img src={x} alt="Close" />
          </div>
        </div>
      </div>
    </FileUploadContainer>
  )
}

function FileDropped({ fileName, fileSize, onRemove }) {
  function handleRemove() {
    onRemove(fileName)
  }

  return (
    <FileUploadContainer>
      <div className={'empty'}>
        <div className="icon">{/* <img src={check} /> */}</div>

        <span>{fileName}</span>

        <div className="close-container">
          <span className="file-size">{displayFileSize(fileSize, 1)}</span>

          <div className="icon close" onClick={handleRemove}>
            <img src={x} alt="Close" />
          </div>
        </div>
      </div>
    </FileUploadContainer>
  )
}

export { FileError, FileSuccess, FileDropped }
