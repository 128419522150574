import React from 'react'
import styled from '@emotion/styled'
import ReactDropzone from 'react-dropzone'
import upload from '../../images/upload-copy@3x.png'
import useDropzone from './useDropzone'
import uploadOutlineGrey from '../../images/upload-bar-grey@3x.png'
import uploadOutlineLarge from '../../images/upload-bar@3x.png'

const DropzoneContainer = styled.div`
  margin-bottom: 10px;
  padding-bottom: 60px;
  cursor: pointer;

  .dropzone {
    z-index: 4;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 0;
  }

  .files {
    max-height: 295px;
    overflow: scroll;
  }

  .placeholder {
    z-index: 4;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: calc(100% - 72px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    transition: top 0.2s;
    background-color: #fff;

    .info {
      display: flex;
      align-items: center;
      span {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.45);
      }
      img {
        width: 24px;
        height: 18px;
        margin-left: 10px;
      }
    }

    .error-message {
      font-size: 14px;
      color: rgba(193, 28, 92, 0.9);
      margin: 0;
    }

    .inner {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: url(${uploadOutlineGrey}) no-repeat;
      background-size: 100% 100%;
    }
  }

  .placeholder.dragging {
    top: 0px;
    .inner {
      background: url(${uploadOutlineLarge}) no-repeat;
      background-size: 100% 100%;
    }
  }

  @media (min-width: ${props => props.theme.screenWidth.medium}) {
    display: flex;
    flex-basis: 100%;
  }
`

function Dropzone({ children, onDrop, setError }) {
  const dragging = useDropzone()

  function handleInvalidFile() {
    setError('Only .jpg, .png, and .pdf files are allowed')
  }

  return (
    <DropzoneContainer>
      <ReactDropzone
        onDrop={onDrop}
        onDropRejected={handleInvalidFile}
        accept="image/png, image/jpeg, application/pdf"
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="files">{children}</div>
            <div {...getRootProps()}>
              <div className={`placeholder ${dragging ? 'dragging' : ''}`}>
                <div className="inner">
                  <input {...getInputProps()} />
                  <div className="info">
                    <span>Upload a PDF, PNG, or JPG</span>
                    <img src={upload} alt="upload" />
                  </div>
                  <p className="error-message">Max file size: 50MB</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </ReactDropzone>
    </DropzoneContainer>
  )
}

export default Dropzone
