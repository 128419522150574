import React from 'react'
import { Field } from 'formik'
import styled from '@emotion/styled'
import {
  TextInput,
  TextArea,
  SelectInput,
  Checkbox,
  TelInput,
} from '../../UI/FormikInputs'
import FormHeader from '../FormHeader'

const UserInformationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 90px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 30px;
    margin-bottom: 40px;
  }
`

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  p {
    flex-basis: 50%;
    font-size: 16px;
    margin: 0;
    margin-bottom: 10px;
  }

  section {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
  }

  label {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
  }

  @media (min-width: ${props => props.theme.screenWidth.medium}) {
    flex-direction: row;
    align-items: center;

    p {
      margin: 0;
    }

    p,
    label {
      font-size: 18px;
    }

    section {
      flex-basis: 50%;
      padding-right: 60px;
    }
  }
`

function UserInformation({ title, options }) {
  return (
    <React.Fragment>
      <FormHeader>{title}</FormHeader>
      <UserInformationContainer>
        <Field
          type="text"
          name="step1.fullName"
          component={TextInput}
          label="Full name"
          required
          ariaLabel="Enter your full name"
          ariaRequired="true"
        />

        <Field
          type="text"
          name="step1.companyName"
          component={TextInput}
          label="Company name"
          required
          ariaLabel="Enter your company name"
          ariaRequired="true"
        />

        <Field
          type="text"
          name="step1.companyRole"
          component={TextInput}
          label="Role at company"
          ariaLabel="Enter your role at the company"
        />

        <Field
          type="select"
          name="step1.numberOfEmployees"
          options={options}
          component={SelectInput}
          label="Number of employees"
          required
          ariaLabel="Enter number of employees"
          ariaRequired="true"
        />

        <Field
          type="email"
          name="step1.email"
          component={TextInput}
          label="Work email"
          required
          ariaLabel="Enter your work email"
          ariaRequired="true"
        />

        <Field
          type="tel"
          name="step1.phoneNumber"
          component={TelInput}
          label="Phone number"
          ariaLabel="Enter your phone number"
        />

        <Checkboxes>
          <p>Preferred method of contact:</p>

          <section>
            <Field
              component={Checkbox}
              name="step1.contactByEmail"
              id="email"
              label="By email"
              ariaLabel="Contact by email"
            />

            <Field
              component={Checkbox}
              name="step1.contactByPhone"
              id="phone"
              label="By phone"
              ariaLabel="Contact by phone"
            />
          </section>
        </Checkboxes>

        <Field
          type="textarea"
          name="step1.referralExplanation"
          component={TextArea}
          label="How did you hear about us?"
          ariaLabel="How did you hear about us?"
        />
      </UserInformationContainer>
    </React.Fragment>
  )
}

export default UserInformation
