import React from 'react'
import styled from '@emotion/styled'
import { useSpring, animated } from 'react-spring'

const ProgressBarContainer = styled.div`
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  width: 100%;

  .bar {
    width: 77%;
    height: 38px;
    position: relative;

    background: linear-gradient(
      96deg,
      #38215d,
      #c8266a 19%,
      #e579ac 65%,
      #8b959c 92%,
      #051f33
    );
  }

  @media (min-width: ${props => props.theme.screenWidth.medium}) {
    .bar {
      width: 83%;
    }
  }

  @media (min-width: ${props => props.theme.screenWidth.large}) {
    .bar {
      width: 85%;
    }
  }

  .mask {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
  }

  .percentage {
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    right: 0;
    color: rgba(0, 0, 0, 0.25);
  }

  @media (min-width: ${props => props.theme.screenWidth.medium}) {
    .percentage {
      font-size: 32px;
    }
  }
`

function ProgressBar({ progress = 0 }) {
  const { percent } = useSpring({
    from: {
      percent: progress === 100 ? 100 : 0,
    },
    percent: progress,
  })

  return (
    <ProgressBarContainer>
      <div className="bar">
        <animated.div
          className="mask"
          style={{ left: percent.interpolate(p => `${p}%`) }}
        />
      </div>

      <animated.span className="percentage">
        {percent.interpolate(p => `${p.toFixed(0)}%`)}
      </animated.span>
    </ProgressBarContainer>
  )
}

export default ProgressBar
