import { useState, useEffect, useRef } from 'react'

// event handlers used from this excellent article:
// https://timcchang.com/blog/react-hook-custom-drag-and-drop-ui/
function useDropzone() {
  const [dragging, set] = useState(false)

  const cachedTarget = useRef(null)

  useEffect(() => {
    function handleDragEnter(event) {
      cachedTarget.current = event.target
    }

    window.addEventListener('dragenter', handleDragEnter)

    function handleDragOver(event) {
      event.preventDefault()

      if (document.hasFocus()) {
        if (!dragging) {
          set(true)
        }
      }
    }

    window.addEventListener('dragover', handleDragOver)

    function handleDragLeave(event) {
      if (event.target === cachedTarget.current) {
        if (dragging) {
          set(false)
        }
      }
    }

    window.addEventListener('dragleave', handleDragLeave)

    function handleDrop(event) {
      event.preventDefault()

      if (dragging) {
        set(false)
      }
    }

    window.addEventListener('drop', handleDrop)

    return () => {
      window.removeEventListener('dragenter', handleDragEnter)
      window.removeEventListener('dragover', handleDragOver)
      window.removeEventListener('dragleave', handleDragLeave)
      window.removeEventListener('drop', handleDrop)
    }
  }, [dragging])

  return dragging
}

export default useDropzone
