import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import StickyNav from '../../components/Navigation/StickyNav'
import gtm from '../../util/gtmEvent'
import ProjectInquiriesForm from '../../components/Contact/forms/ProjectInquiries'

const metaTitle =
  'Project Inquiries - Leading Digital Product Studio | Crowdlinker'

const metaDescription =
  'Looking for a technology partner to help guide your company through digital transformation or build out a new digital product? Reach out to Crowdlinker with your project inquiries.'

export const formQuery = graphql`
  {
    contentfulContactForm(inquiryType: { eq: "project" }) {
      id
      title
      inquiryType
      contactName
      contactEmail
      contactImage {
        file {
          url
        }
        title
      }
      calendarLink
      id
    }
  }
`

export default class Contact extends React.Component {
  state = {
    showNavError: null,
  }

  handleFileError = bool => {
    const error = bool ? 'file' : null
    this.setState({ showNavError: error })
  }

  componentDidMount() {
    // Send event to GTM
    gtm('Contact Us', 'Project Inquiries', 'Contact Us', 'Form 1')
  }

  render() {
    const { showNavError } = this.state

    const formDetails = this.props.data.contentfulContactForm

    return (
      <Layout page="contact">
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={metaDescription} />
        </Helmet>

        <StickyNav showNavError={showNavError} />

        <ProjectInquiriesForm
          formDetails={formDetails}
          handleFileError={this.handleFileError}
        />
      </Layout>
    )
  }
}
