import axios from 'axios'

function getPresignedPostData(file) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: `${process.env.GATSBY_LAMBDA_API_URL}/presigned-post-data`,
      data: {
        name: file.name,
        type: file.type,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

function uploadFileToS3(presignedPostData, file, onUploadProgress) {
  return new Promise((resolve, reject) => {
    const formData = new FormData()

    Object.keys(presignedPostData.fields).forEach(key => {
      formData.append(key, presignedPostData.fields[key])
    })

    formData.append('file', file)

    axios({
      method: 'POST',
      url: presignedPostData.url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onUploadProgress,
    })
      .then(() => {
        const url = `${presignedPostData.url}/${presignedPostData.fields.key}`
        resolve({ url })
      })
      .catch(reject)
  })
}

export { getPresignedPostData, uploadFileToS3 }
