import React, { useEffect, useState } from 'react'
import { Field } from 'formik'
import styled from '@emotion/styled'
import FormHeader from '../FormHeader'
import Dropzone from '../Dropzone'
import { Checkbox, TextArea } from '../../UI/FormikInputs'
import ProgressBar from '../ProgressBar'
import { FileSuccess, FileError, FileDropped } from '../FileUpload'
import { getPresignedPostData, uploadFileToS3 } from '../upload-files'

const UploadImagesContainer = styled.div`
  margin-top: 90px;
  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    margin-top: 74px;
  }

  section {
    position: relative;
    // do not remove below -- weird
    border: thin solid transparent;
  }

  .spacer {
    margin-top: 27px;
  }
`

const UPLOAD_LIMIT_BYTES = 1024 * 1024 * 50 // 50mb

function UploadImages({ setFieldValue, values, setFileError, isSubmitting }) {
  let { files } = values.step2

  function handleDrop(newFiles) {
    const totalFiles = [
      ...files,
      ...newFiles.map(f => ({ file: f, loaded: false, url: '' })),
    ]

    setFieldValue('step2.files', totalFiles)
  }

  function updateFileLoaded(fileName, url) {
    files = files.map(file => {
      if (file.file.name === fileName) {
        file.loaded = true
        file.url = url
      }

      return file
    })

    setFieldValue('step2.files', files)
  }

  function removeFile(index) {
    setFieldValue('step2.files', files.filter((_, i) => i !== index))
  }

  useEffect(() => {
    setFileError('')

    files.forEach(file => {
      if (file.file.size > UPLOAD_LIMIT_BYTES) {
        setFileError(
          'The file you’ve selected is too large. Files should be 50MB or less.'
        )
      }
    })
  }, [files])

  return (
    <React.Fragment>
      <FormHeader>Finishing touches...</FormHeader>
      <UploadImagesContainer>
        <section>
          <Field
            name="step2.projectDescription"
            component={TextArea}
            large
            label="Tell us a little bit about your project."
          />

          <Dropzone onDrop={handleDrop} setError={setFileError}>
            {
              <div className="drop-container">
                {files.map((file, index) => {
                  const loaded = file.loaded

                  return (
                    <File
                      key={index}
                      file={file}
                      onSuccess={updateFileLoaded}
                      onRemove={() => removeFile(index)}
                      loaded={loaded}
                      setFileError={setFileError}
                      isSubmitting={isSubmitting}
                    />
                  )
                })}
              </div>
            }
          </Dropzone>
        </section>

        <div className="spacer">
          <Field
            name="step2.newsletterSignup"
            component={Checkbox}
            label="Get free tips on how to build a better product from our newsletter"
          />
        </div>
      </UploadImagesContainer>
    </React.Fragment>
  )
}

import dottedOutlineGrey from '../../../images/upload-bar-grey@3x.png'

const FileContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0;

  background: url(${dottedOutlineGrey}) no-repeat;
  background-size: 100% 100%;
`

function File({ file, onSuccess, onRemove, isSubmitting, setFileError }) {
  const [progress, loading, error] = useUploadFile(
    file,
    handleSuccess,
    isSubmitting
  )
  const [success, setSuccess] = useState(file.loaded)

  function handleSuccess(url) {
    setSuccess(true)
    onSuccess(file.file.name, url)
  }

  useEffect(() => {
    if (error) {
      setFileError('Something went wrong,  please try again')
    }
  }, [error])

  const fileName = file.file.name
  const fileSize = file.file.size

  return (
    <FileContainer>
      {success || file.loaded ? (
        <FileSuccess
          fileName={fileName}
          fileSize={fileSize}
          onRemove={onRemove}
        />
      ) : error ? (
        <FileError
          fileName={fileName}
          fileSize={fileSize}
          onRemove={onRemove}
        />
      ) : loading ? (
        <ProgressBar progress={progress} />
      ) : (
        <FileDropped
          fileName={fileName}
          fileSize={fileSize}
          onRemove={onRemove}
        />
      )}
    </FileContainer>
  )
}

function useUploadFile(file, onSuccess, isSubmitting) {
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState('')

  function handleProgress(progressEvent) {
    const percentLoaded = (progressEvent.loaded / progressEvent.total) * 100
    setProgress(percentLoaded)
  }

  function handleFileUpload(response) {
    uploadFileToS3(response.data.data, file.file, handleProgress)
      .then(({ url }) => {
        setLoading(false)
        onSuccess(url)
      })
      .catch(err => {
        setLoading(false)
        setError(err.message)
      })
  }

  useEffect(() => {
    if (file.file.size < UPLOAD_LIMIT_BYTES) {
      if (!file.loaded && isSubmitting) {
        setLoading(true)
        getPresignedPostData(file.file)
          .then(handleFileUpload)
          .catch(err => {
            setError(err.message)
          })
      }
    }
  }, [file, isSubmitting])

  return [progress, loading, error]
}

export default UploadImages
