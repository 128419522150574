import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import styled from '@emotion/styled'
import axios from 'axios'

import UserInformation from '../screens/UserInformation'
import UploadImages from '../screens/UploadImages'
import SuccessScreen from '../screens/SuccessScreen'
import StickyNav from '../../Navigation/StickyNav'

import Panels from '../Panels'
import FormProgress from '../FormProgress'
import { FormContainer } from '../Containers'
import useDropzone from '../useDropzone'
import gtm from '../../../util/gtmEvent'

const phoneRe = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/

const validationSchema = yup.object().shape({
  step1: yup.object().shape({
    fullName: yup.string().required('Please enter your full name'),
    companyName: yup.string().required('Please enter your company name'),
    companyRole: yup.string(),
    numberOfEmployees: yup.string().required('Please select a number'),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Please enter your work email'),
    phoneNumber: yup.string().matches(phoneRe, {
      message: 'Invalid phone number format. Ex: ###-###-####',
      excludeEmptyString: true,
    }),
    contactByEmail: yup.boolean(),
    contactByPhone: yup.boolean(),
    referralExplanation: yup.string(),
  }),
})

const initialFormValues = {
  step1: {
    fullName: '',
    companyName: '',
    companyRole: '',
    numberOfEmployees: '',
    email: '',
    phoneNumber: '',
    contactByEmail: false,
    contactByPhone: false,
    referralExplanation: '',
  },
  step2: {
    projectDescription: '',
    files: [],
    newsletterSignup: false,
  },
}

const options = [
  { value: '1-20', label: '1-20' },
  { value: '21-50', label: '21-50' },
  { value: '51-100', label: '51-100' },
  { value: '101-199', label: '101-199' },
  { value: '200+', label: '200+' },
]

const Overlay = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: ${props => (props.active ? 'block' : 'none')};

  background-color: rgba(0, 0, 0, 0.25);
`
const ProjectInquiries = ({ formDetails }) => {
  const dragging = useDropzone()

  const { title, inquiryType } = formDetails
  const [error, setError] = useState('')

  function handleSubmit(values, actions) {
    // this will trigger uploading files, then submitting the form
    if (!error) {
      actions.setSubmitting(true)
    }
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialFormValues}
      initialStatus={{ activeIndex: 0 }}
      onSubmit={handleSubmit}
      render={({
        status: { activeIndex },
        setStatus,
        submitForm,
        errors,
        dirty,
        values,
        setFieldValue,
        isSubmitting,
        validateForm,
        setTouched,
        ...rest
      }) => {
        const disableNext = !dirty || errors.step1 !== undefined

        function next() {
          if (activeIndex === 1) {
            submitForm()
          } else {
            // trigger any error messages on Next press
            validateForm()
            setTouched({
              step1: {
                fullName: true,
                companyName: true,
                companyRole: true,
                numberOfEmployees: true,
                email: true,
                phoneNumber: true,
                contactByEmail: true,
                contactByPhone: true,
                referralExplanation: true,
              },
            })

            if (!disableNext) {
              // Send event to GTM
              gtm(
                'Contact Us',
                'Project Inquiries',
                'Contact Us',
                'Form 2 (Project Details)'
              )
              setStatus({ activeIndex: activeIndex + 1 })
            }
          }
        }

        function prev() {
          if (activeIndex === 0) {
            navigate('../contact')
          } else {
            setStatus({ activeIndex: activeIndex - 1 })
          }
        }

        const [firstName] = values.step1.fullName
          ? values.step1.fullName.split(' ')
          : ''

        return (
          <UploadFilesAndSubmit
            {...rest}
            values={values}
            inquiryType={inquiryType}
            error={error}
            setError={setError}
            setStatus={setStatus}
            isSubmitting={isSubmitting}
          >
            <StickyNav showNavError={error} errorMessage={error} />
            <FormContainer>
              <Form>
                <Panels activeIndex={activeIndex}>
                  <UserInformation options={options} title={title} />
                  <UploadImages
                    setFieldValue={setFieldValue}
                    values={values}
                    setFileError={setError}
                    isSubmitting={isSubmitting}
                  />
                  <SuccessScreen
                    usersName={firstName}
                    contactName={formDetails.contactName}
                    contactImage={`https:${formDetails.contactImage.file.url}`}
                    imageDescription={formDetails.contactImage.description}
                    calendarLink={formDetails.calendarLink}
                    inquiryType={formDetails.inquiryType}
                  />
                </Panels>

                <FormProgress
                  steps={3}
                  activeIndex={activeIndex + 1}
                  next={next}
                  prev={prev}
                  disableNext={disableNext}
                  isSubmitting={isSubmitting}
                />
              </Form>
              <Overlay active={activeIndex === 1 && dragging} />
            </FormContainer>
          </UploadFilesAndSubmit>
        )
      }}
    />
  )
}

function UploadFilesAndSubmit({
  values,
  inquiryType,
  isSubmitting,
  setSubmitting,
  setStatus,
  children,
  error,
  setError,
}) {
  useEffect(() => {
    if (error) {
      setSubmitting(false)
    } else {
      if (isSubmitting) {
        const uploading = values.step2.files.some(f => !f.loaded)

        if (!uploading) {
          axios
            .post(`${process.env.GATSBY_LAMBDA_API_URL}/project`, {
              ...values.step1,
              ...values.step2,
              inquiryType,
            })
            .then(() => {
              setSubmitting(false)
              setStatus({ activeIndex: 2 })
              // Send event to GTM
              gtm(
                'Contact Us',
                'Project Inquiries',
                'Contact Us',
                'Thank you screen'
              )
            })
            .catch(() => {
              // TODO: send errors from backend
              setError('Something went wrong, please try again later!')
              setSubmitting(false)
            })
        }
      }
    }
  }, [values.step2.files, isSubmitting, error])

  return children
}

export default ProjectInquiries
